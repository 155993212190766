import '../../css/components/home/menus/content.css';

import {Button, Drawer, Empty, Image, message, Popconfirm, Tooltip, Typography} from "antd";
import {useEffect, useState} from "react";
import {CopyOutlined, DownloadOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {useMediaQuery} from "react-responsive";
import ReactQuill from "react-quill";
import {useNavigate, useParams} from "react-router-dom";
import {useTypedSelector} from "../../hooks/redux/useTypedSelector";
import {useActions} from "../../hooks/redux/useActions";
import instance from "../../api/configuration/configure";
import ShowAvatar from "../common/avatarImage/avatar";
import {UserRoles} from "../../constants/auth/userRoles";
import {ResearchProjectTypes} from "../../constants/home/researchProjectTypes";

const MainContent = () => {
    const desktopXtablet = useMediaQuery({minWidth: 768});
    const smartphone = useMediaQuery({maxWidth: 767});

    const {details} = useTypedSelector((x) => x.home);
    const {user} = useTypedSelector((x) => x.auth);
    const {
        GetResearchProjectDetails,
        SetDetailsProjectEmpty,
        SetOpenUpdateResearchProjectModal,
        RemoveListIdOnDelete,
    } = useActions();
    const {id} = useParams();
    const navigate = useNavigate();

    const [coverImage, SetCoverImage] = useState<string>("");
    const [file, SetFile] = useState<string>("");
    const [smartphoneOpen, setSmartphoneOpen] = useState<boolean>(false);

    useEffect(() => {
        if (id !== undefined) {
            GetResearchProjectDetails(id as string);

            if (smartphone) {
                setSmartphoneOpen(true);
            }
        }
    }, [id]);

    useEffect(() => {
        if (id !== undefined) {
            let imageUrl = "";
            instance
                .get(
                    `${process.env.REACT_APP_SERVER_URL}storage/image?filename=${details.coverImageName}`,
                    {
                        responseType: "blob",
                    }
                ).then((imageResponse: any) => {
                imageUrl = URL.createObjectURL(imageResponse.data);
                SetCoverImage(imageUrl);
            }).catch();

            let fileUrl = "";
            if (details.fileName !== "") {
                instance
                    .get(
                        `${process.env.REACT_APP_SERVER_URL}storage/file?filename=${details.fileName}`,
                        {
                            responseType: "blob",
                        }
                    ).then((fileResponse) => {
                    fileUrl = URL.createObjectURL(fileResponse.data);
                    SetFile(fileUrl);
                });
            }

            return (() => {
                if (imageUrl !== "") {
                    URL.revokeObjectURL(imageUrl);
                }

                if (fileUrl !== "") {
                    URL.revokeObjectURL(fileUrl);
                }
            });
        }
    }, [details]);

    return (
        <>
            {desktopXtablet && (<section className="section-content hide-scrollbar">
                {id === undefined ? (
                    <div className="content-clear-wrapper">
                        <Empty
                            className="empty-logo-content noselect"
                            description="Оберіть наукову роботу"
                        />
                    </div>
                ) : (
                    <div className="content-wrapper">
                        <div>
                            <div className="content-wrapper-project-name">
                                <Typography className="content-wrapper-project-name-text">
                                    {details.name}
                                </Typography>
                            </div>
                            <div>
                                <Typography className="content-wrapper-project-keywords-text">
                                    Ключові слова: {details.tags.map((x) => x + ", ")}..
                                </Typography>
                            </div>
                            <div className="content-wrapper-author-links-block">
                                <div className="content-wrapper-author-block">
                                    <ShowAvatar value={details.authorAvatar}/>
                                    <Typography className="content-wrapper-author-text">
                                        {details.author}
                                    </Typography>
                                </div>
                                <div className="content-wrapper-links-block">
                                    <Tooltip title="Посилання до наукової роботи для не зареєстрованих користувачів"
                                             placement="topRight" className="content-copy-link-wrapper">
                                        <Typography>Публічне посилання</Typography>
                                        <CopyOutlined className="content-copy-link" onClick={async () => {
                                            await navigator.clipboard.writeText(window.location.href.replace("/home", ''))
                                        }}/>
                                    </Tooltip>
                                    <Tooltip title="Посилання до наукової роботи для зареєстрованих користувачів"
                                             placement="topRight" className="content-copy-link-wrapper">
                                        <Typography>Приватне посилання</Typography>
                                        <CopyOutlined className="content-copy-link" onClick={async () => {
                                            await navigator.clipboard.writeText(window.location.href)
                                        }}/>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="content-wrapper-down-block">
                                <div className="content-wrapper-image-admin-buttons">
                                    <Image
                                        src={coverImage}
                                        className="content-wrapper-cover-image"
                                        alt="Cover Image"
                                    />
                                    {(details.fileName !== "" ||
                                        user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                        <div className="content-wrapper-admin-buttons-block">
                                            {(details.isVerified !== true || user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                                <Button
                                                    className="content-wrapper-full-space"
                                                    onClick={() => SetOpenUpdateResearchProjectModal(true)}
                                                >
                                                    Редагувати
                                                </Button>
                                            )}
                                            {(details.isVerified === false || user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                                <Popconfirm
                                                    title="Видалення проєкту"
                                                    description="Чи ви впевнені щодо видалення цього проєкту?"
                                                    onConfirm={() => {
                                                        instance
                                                            .delete("api/research-project?id=" + details.id)
                                                            .then((response) => {
                                                                SetDetailsProjectEmpty();
                                                                RemoveListIdOnDelete(details.id);
                                                                navigate("/home");

                                                                message.success(response.data.message);
                                                            })
                                                            .catch((error) => {
                                                                message.error(error?.response.data.message);
                                                            });
                                                    }}
                                                    icon={
                                                        <QuestionCircleOutlined className="content-wrapper-red-icon"/>
                                                    }
                                                >
                                                    <Button className="content-wrapper-full-space">Видалити</Button>
                                                </Popconfirm>
                                            )}
                                        </div>
                                    )}
                                    {(details.fileName !== "" ||
                                        user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                        <a href={file} className="content-wrapper-full-width"
                                           download={details.name.replace(/ /g, '_') + ".pdf"}>
                                            <Button className="content-wrapper-full-width">
                                                <DownloadOutlined/>Завантажити проєкт
                                            </Button>
                                        </a>
                                    )}
                                </div>
                                <div className="content-details-description">
                                    <ReactQuill
                                        value={details.description}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="content-wrapper-dates-block">
                            <Typography>
                                <b>Дата створення: </b>
                                {details.dateCreated}
                            </Typography>
                            <Typography className="text-speciality-content">
                                {details.categoryName} (
                                {details.type === 0
                                    ? ResearchProjectTypes.GRADUATE_WORK
                                    : details.type === 1
                                        ? ResearchProjectTypes.COURSEWORK
                                        : details.type === 2
                                            ? ResearchProjectTypes.EDUCATIONAL_MATERIALS
                                            : ResearchProjectTypes.UNKNOWN_WORK_TYPE}
                                )
                            </Typography>
                            <Typography>
                                <b>Дата редагування: </b>
                                {details.dateEdited}
                            </Typography>
                        </div>
                    </div>
                )}
            </section>)}
            {smartphone && (<Drawer placement="top"
                                    closable={true}
                                    onClose={() => {
                                        setSmartphoneOpen(false);
                                        navigate("/home");
                                    }}
                                    width="100%"
                                    height="100%"
                                    open={smartphoneOpen}
                                    className="section-content">
                {id === undefined ? (
                    <div className="content-clear-wrapper">
                        <Empty
                            className="empty-logo-content noselect"
                            description="Оберіть наукову роботу"
                        />
                    </div>
                ) : (
                    <section>
                        <div className="content-wrapper-project-name">
                            <Typography className="content-wrapper-project-name-text">
                                {details.name}
                            </Typography>
                        </div>
                        <div>
                            <Typography className="content-wrapper-project-keywords-text">
                                Ключові слова: {details.tags.map((x) => x + ", ")}..
                            </Typography>
                        </div>
                        <div className="content-wrapper-author-block">
                            <ShowAvatar value={details.authorAvatar}/>
                            <Typography className="content-wrapper-author-text">
                                {details.author}
                            </Typography>
                        </div>
                        <div>
                            <div className="content-wrapper-cover-image-block-mobile">
                                <Image
                                    src={coverImage}
                                    className="content-wrapper-cover-image"
                                    alt="Cover Image"
                                />
                            </div>
                            {(details.fileName !== "" ||
                                user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                <div className="content-wrapper-admin-buttons-block">
                                    {(details.isVerified !== true || user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                        <Button
                                            className="content-wrapper-full-space"
                                            onClick={() => SetOpenUpdateResearchProjectModal(true)}
                                        >
                                            Редагувати
                                        </Button>
                                    )}
                                    {(details.isVerified === false || user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                        <Popconfirm
                                            title="Видалення проєкту"
                                            description="Чи ви впевнені щодо видалення цього проєкту?"
                                            onConfirm={() => {
                                                instance
                                                    .delete("api/research-project?id=" + details.id)
                                                    .then((response) => {
                                                        SetDetailsProjectEmpty();
                                                        RemoveListIdOnDelete(details.id);
                                                        navigate("/home");

                                                        message.success(response.data.message);
                                                    })
                                                    .catch((error) => {
                                                        message.error(error?.response.data.message);
                                                    });
                                            }}
                                            icon={
                                                <QuestionCircleOutlined className="content-wrapper-red-icon"/>
                                            }
                                        >
                                            <Button
                                                className="content-wrapper-full-width content-wrapper-full-space">Видалити</Button>
                                        </Popconfirm>
                                    )}
                                </div>
                            )}
                            {(details.fileName !== "" ||
                                user?.roles.includes(UserRoles.ADMINISTRATOR)) && (
                                <a href={file} className="content-wrapper-full-width"
                                   download={details.name.replace(/ /g, '_') + ".pdf"}>
                                    <Button className="content-wrapper-full-width">
                                        <DownloadOutlined/>Завантажити проєкт
                                    </Button>
                                </a>
                            )}
                        </div>
                        <div className="content-details-description-mobile">
                            <ReactQuill
                                value={details.description}
                                readOnly={true}
                                theme="bubble"
                            />
                        </div>
                        <div className="content-wrapper-dates-block">
                            <Typography>
                                <b>Дата створення: </b>
                                {details.dateCreated}
                            </Typography>
                            <Typography className="text-speciality-content">
                                {details.categoryName} (
                                {details.type === 0
                                    ? ResearchProjectTypes.GRADUATE_WORK
                                    : details.type === 1
                                        ? ResearchProjectTypes.COURSEWORK
                                        : details.type === 2
                                            ? ResearchProjectTypes.EDUCATIONAL_MATERIALS
                                            : ResearchProjectTypes.UNKNOWN_WORK_TYPE}
                                )
                            </Typography>
                            <Typography>
                                <b>Дата редагування: </b>
                                {details.dateEdited}
                            </Typography>
                        </div>
                    </section>
                )}
            </Drawer>)}
        </>
    );
};

export default MainContent;
