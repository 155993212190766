import '../../../css/components/auth/emailConfrimation.css';

import {Result} from "antd";
import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useActions} from "../../../hooks/redux/useActions";
import {IConfirmEmail} from "../../../types/auth/emailTypes";

const EmailConfirmation = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {EmailConfirm} = useActions();

    useEffect(() => {
        document.title = "Підтвердження реєстрації | РФК НУБіП";

        EmailConfirm({
            email: searchParams.get("email"),
            token: searchParams.get("token"),
        } as IConfirmEmail);

        setTimeout(() => {
            navigate("/login");
        }, 3500);
    }, []);

    return (
        <section className="confirm-email-wrapper">
            <Result
                status="success"
                title="Підтвердження реєстрації"
                subTitle="Дякуємо, ваша почтова скринька підтверджена!"
            />
        </section>
    );
};

export default EmailConfirmation;
