import axios from "axios";
import {message} from "antd";
import {Dispatch} from "react";
import {ICreateRegister, IRegisterSuccessAction} from "../../../types/auth/registerTypes";
import {AuthActionType} from "../../../types/stores/authStoreTypes";
import {IAuthProvider} from "../../../components/common/googleAuth/googleAuth";

export const RegisterUser = (data: ICreateRegister) => {
    return async (dispatch: Dispatch<IRegisterSuccessAction>) => {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER_URL + "api/auth/register", data);
            dispatch({
                type: AuthActionType.REGISTER_AUTH,
            });

            message.success(response?.data.message);
            return Promise.resolve(true);
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject(false);
        }
    };
};

export const GoogleRegisterUser = (data: IAuthProvider) => {
    return async (dispatch: Dispatch<IRegisterSuccessAction>) => {
        axios
            .post(process.env.REACT_APP_SERVER_URL + 'api/auth/google-register', data)
            .then((response) => {
                dispatch({
                    type: AuthActionType.REGISTER_AUTH,
                });

                message.success(response?.data.message);

                return Promise.resolve();
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    message.error(err.response?.data.message);
                }

                return Promise.reject();
            });
    };
};

export const GoogleOneTapRegisterUser = (data: IAuthProvider) => {
    return async (dispatch: Dispatch<IRegisterSuccessAction>) => {
        console.log(data)
        axios
            .post(process.env.REACT_APP_SERVER_URL + 'api/auth/google-one-tap-register', data)
            .then((response) => {
                dispatch({
                    type: AuthActionType.REGISTER_AUTH,
                });

                message.success(response?.data.message);

                return Promise.resolve();
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    message.error(err.response?.data.message);
                }

                return Promise.reject();
            });
    };
};
