import "../../../css/common/googleAuth.css";
import googleLogo from '../../../assets/logos/google-logo.svg';

import {useGoogleLogin, useGoogleOneTapLogin} from '@react-oauth/google';
import {Button, message} from 'antd';

export interface IGoogleAuth {
    handleGoogleOneTapSuccess(response: any): void;

    handleGoogleSuccess(response: any): void;

    text: string;
}

export interface IAuthProvider {
    provider: string;
    token: string;
}

const GoogleAuth = ({handleGoogleOneTapSuccess, handleGoogleSuccess, text}: IGoogleAuth) => {
    useGoogleOneTapLogin({
        onSuccess: handleGoogleOneTapSuccess,
        onError: () => {
            message.error("Авторизація через Google не була успішною з певних причин.");
        },
    });

    const auth = useGoogleLogin({
        onSuccess: handleGoogleSuccess,
        onError: () => {
            message.error("Авторизація через Google не була успішною з певних причин.");
        },
        flow: 'implicit',
    });

    return (
        <Button
            type='primary'
            onClick={() => auth()}
            className="google-button"
            ghost
        >
            <img src={googleLogo} className="google-logo" alt="Google logotype"/>
            <p>{text}</p>
        </Button>
    );
};

export default GoogleAuth;
