import {Dispatch} from "react";
import axios from "axios";
import {message} from "antd";
import {IGetShortInfoResearchProjectSuccessAction} from "../../../types/home/privateDetailsTypes";
import instance from "../../../api/configuration/configure";
import {AuthActionType} from "../../../types/stores/authStoreTypes";

export const GetShortInfoByIdResearchProject = (id: string) => {
    return async (dispatch: Dispatch<IGetShortInfoResearchProjectSuccessAction>) => {
        if (id === undefined) {
            return;
        }

        instance.get(`api/research-project/get-short-info/${id}`)
            .then((response: any) => {
                dispatch({
                    type: AuthActionType.GET_SHORT_INFO_RESEARCH_PROJECT_AUTH,
                    payload: response.data,
                });

                return Promise.resolve();
            })
            .catch((error) => {
                return Promise.resolve();
                if (axios.isAxiosError(error)) {
                    message.error(error.response?.data.message);
                }

                return Promise.reject();
            });
    };
};
