import '../../css/components/home/menus/searchMenu.css';

import slider from '../../assets/icons/slider.svg';
import {SearchOutlined} from "@ant-design/icons";
import {AutoComplete, Input} from "antd";
import {useMediaQuery} from "react-responsive";
import {useActions} from "../../hooks/redux/useActions";
import {useTypedSelector} from "../../hooks/redux/useTypedSelector";

const SearchMenu = () => {
    const desktopXtablet = useMediaQuery({minWidth: 768});
    const smartphone = useMediaQuery({maxWidth: 767});

    const {
        SetSiderQuery,
        SetOpenSectionSiderFiltersMenu,
    } = useActions();
    const {siderData} =
        useTypedSelector((x: any) => x.home);

    const optionsProject = siderData.items.map((result: any) => ({
        value: result.name,
        label: result.name,
    }));

    return (
        <>
            {desktopXtablet && (<section className="search-menu-wrapper">
                <a onClick={() => SetOpenSectionSiderFiltersMenu(true)} className="search-menu-btn-filters-menu">
                    <img className="search-menu-filters-icon noselect" src={slider} alt="Slider logo"/>
                </a>

                <AutoComplete
                    options={optionsProject}
                    onChange={(value) => SetSiderQuery(value)}
                    className="search-menu-query-auto-complete"
                    backfill
                    allowClear
                >
                    <Input prefix={<SearchOutlined/>} placeholder="Введіть назву наукової роботи"/>
                </AutoComplete>
            </section>)}
            {smartphone && (
                <a onClick={() => SetOpenSectionSiderFiltersMenu(true)} className="search-menu-btn-filters-menu">
                    <SearchOutlined className="search-menu-filters-icon noselect"/>
                </a>
            )}
        </>
    );
}

export default SearchMenu;
