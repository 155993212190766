import axios, {AxiosError} from "axios";
import {refreshToken} from "../refreshToken";
import store from "../../redux/configuration/configureStore";
import {LogOutSpecial} from "../../redux/actions/auth/loginActions";

type RequestQueueItem = {
    config: any;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
};

let isRefreshing = false;
let failedQueue: RequestQueueItem[] = [];

const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_SERVER_URL,
    headers: {"Content-Type": "application/json"},
});

const processQueue = (error: AxiosError | null, token: string | null = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(instance(prom.config));
        }
    });

    failedQueue = [];

    if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
};

instance.interceptors.request.use(
    (configuration: any) => {
        const accessToken = localStorage.getItem("token");

        if (accessToken) {
            if (configuration.headers) {
                configuration.headers.Authorization = `Bearer ${accessToken}`;
            }
        }

        return configuration;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (
            (error.response.status === 404 || error.response.status === 500) &&
            error.config.url === process.env.REACT_APP_SERVER_URL + "api/auth/refresh-token"
        ) {
            await store.dispatch(await LogOutSpecial());
        }

        if (error.response.status === 401 && !error.config._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                const localRefreshToken = localStorage.getItem("refreshToken");

                try {
                    const result = await refreshToken(localRefreshToken as string);

                    const newAccessToken = result.data.token;
                    const newRefreshToken = result.data.refreshToken;

                    localStorage.setItem("token", newAccessToken);
                    localStorage.setItem("refreshToken", newRefreshToken);

                    processQueue(null, newAccessToken);
                } catch (internalError) {
                    processQueue(internalError as any, null);
                } finally {
                    isRefreshing = false;
                }
            }

            return new Promise((resolve, reject) => {
                failedQueue.push({resolve, reject, config: error.config});
            });
        }

        return Promise.reject(error);
    }
);

export default instance;
