import {Dispatch} from "react";
import {HomeActionType} from "../../../types/stores/homeStoreTypes";
import {message} from "antd";
import axios from "axios";
import instance from "../../../api/configuration/configure";
import {
    IGetResearchProjectDetailsSuccessAction,
    IRemoveListIdOnDeleteSuccessAction
} from "../../../types/home/contentTypes";

export const GetResearchProjectDetails = (id: string) => {
    return async (
        dispatch: Dispatch<IGetResearchProjectDetailsSuccessAction>
    ) => {
        try {
            const response = await instance.get("api/research-project/" + id);
            dispatch({
                type: HomeActionType.GET_RESEARCH_PROJECT_DETAILS_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const RemoveListIdOnDelete = (id: string) => {
    return async (
        dispatch: Dispatch<IRemoveListIdOnDeleteSuccessAction>
    ) => {
        dispatch({
            type: HomeActionType.DELETE_FROM_LIST_BY_ID_HOME,
            payload: id,
        });

        return Promise.resolve();
    };
};
