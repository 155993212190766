import axios from "axios";

import {
    IChangeAvatarImage,
    ICreateCategorySuccessAction,
    ICreateResearchProjectSuccessAction,
    ICreateStudent,
    ICreateStudentsExcelSuccessAction,
    ICreateStudentSuccessAction,
    IGetApprovementProjectSuccessAction,
    IGetEmailUserListSuccessAction,
    IGetResearchProjectDataSuccessAction,
    IGetRoleListAction,
    IGetStudentListSuccessAction,
    ISetDetailsProjectEmptySuccessAction,
    ISetEmptyMediaFields,
    ISetFileCreateProjectModelSuccessAction,
    ISetGetUsersManageListSuccessAction,
    ISetOpenAddModalModalSuccessAction,
    ISetOpenCreateProjectModalSuccessAction,
    ISetOpenProjectApprovementModalSuccessAction,
    ISetOpenRoleModalSuccessAction,
    ISetOpenStudentListModalSuccessAction,
    ISetOpenStudentPromoteModalSuccessAction,
    ISetOpenUpdateResearchProjectModalSuccessAction,
    ISetOpenUsersManageListMenuSuccessAction,
    ISetUserRole,
    IStudentPromoteSuccessAction,
    IUpdateCategorySuccessAction,
    IUpdateResearchProjectSuccessAction,
} from "../../../types/home/modalsTypes";
import {Dispatch} from "react";
import {message} from "antd";
import {HomeActionType, IUpdateResearchProject} from "../../../types/stores/homeStoreTypes";
import instance from "../../../api/configuration/configure";

export const SetOpenCreateProjectModal = (value: boolean) => {
    return async (
        dispatch: Dispatch<ISetOpenCreateProjectModalSuccessAction>
    ) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_CREATE_PROJECT_MODAL_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenUsersManageListMenuModal = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenUsersManageListMenuSuccessAction>) => {
        dispatch({
            type: HomeActionType.SET_OPEN_USERS_MANAGE_LIST_MENU_H0ME,
            payload: value,
        });

        return Promise.resolve();
    };
};

export const GetUsersManageList = (page: number, query: string) => {
    return async (dispatch: Dispatch<ISetGetUsersManageListSuccessAction>) => {
        try {
            if (query == null) {
                return;
            }

            const response = await instance.get("api/user/get-users-info?page=" + page + "&query=" + query);

            dispatch({
                type: HomeActionType.GET_USERS_MANAGE_LIST_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenStudentPromoteModal = (value: boolean) => {
    return async (
        dispatch: Dispatch<ISetOpenStudentPromoteModalSuccessAction>
    ) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_STUDENT_PROMOTE_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenRoleModal = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenRoleModalSuccessAction>) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_ROLE_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenProjectApprovementModal = (value: boolean) => {
    return async (
        dispatch: Dispatch<ISetOpenProjectApprovementModalSuccessAction>
    ) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_PROJECT_APPROVEMENT_MODEL_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetFileCreateProjectModel = (file: any, id: any = null) => {
    return async (
        dispatch: Dispatch<ISetFileCreateProjectModelSuccessAction>
    ) => {
        try {
            const formData = new FormData();

            formData.append("id", id);
            formData.append("file", file);

            const response = await instance.post(
                "api/research-project/file-load",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                }
            );

            dispatch({
                type: HomeActionType.SET_FILE_CREATE_PROJECT_MODEL,
                payload: response.data.id,
            });

            message.success(response.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const CreateResearchProject = (data: any) => {
    return async (dispatch: Dispatch<ICreateResearchProjectSuccessAction>) => {
        try {
            const response = await instance.post("api/research-project", data);

            dispatch({
                type: HomeActionType.CREATE_RESEARCH_PROJECT_HOME,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const StudentPromote = (data: any) => {
    return async (dispatch: Dispatch<IStudentPromoteSuccessAction>) => {
        try {
            const response = await instance.post(
                "api/approvement/student-promote",
                data
            );

            dispatch({
                type: HomeActionType.STUDENT_PROMOTE_HOME,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const CreateCategory = (data: any) => {
    return async (dispatch: Dispatch<ICreateCategorySuccessAction>) => {
        try {
            const response = await instance.post("api/category", data);

            dispatch({
                type: HomeActionType.CREATE_CATEGORY_HOME,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const UpdateCategory = (data: any) => {
    return async (dispatch: Dispatch<IUpdateCategorySuccessAction>) => {
        try {
            const response = await instance
                .put("api/category", data)
                .catch((x: any) => console.log(x));

            dispatch({
                type: HomeActionType.UPDATE_CATEGORY_HOME,
                payload: data,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetEmailUserList = (email: string) => {
    return async (dispatch: Dispatch<IGetEmailUserListSuccessAction>) => {
        try {
            const response = await instance.get(
                "api/auth/users-email-list?email=" + email
            );

            dispatch({
                type: HomeActionType.GET_EMAIL_USER_LIST_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const GetRolesList = () => {
    return async (dispatch: Dispatch<IGetRoleListAction>) => {
        try {
            const response = await instance.get("api/auth/roles-list");

            dispatch({
                type: HomeActionType.GET_ROLE_LIST_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const SetUserRole = (data: any) => {
    return async (dispatch: Dispatch<ISetUserRole>) => {
        try {
            const response = await instance.post("api/auth/roles", data);

            dispatch({
                type: HomeActionType.GET_ROLE_LIST_HOME,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetEmptyMediaFields = () => {
    return async (dispatch: Dispatch<ISetEmptyMediaFields>) => {
        dispatch({
            type: HomeActionType.SET_EMPTY_MEDIA_FIELDS,
        });

        return Promise.resolve();
    };
};

export const ChangeAvatarImage = (image: any) => {
    return async (dispatch: Dispatch<IChangeAvatarImage>) => {
        try {
            const formData = new FormData();

            formData.append("image", image);

            const response = await instance.put(
                "api/user/set-avatar-image",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                }
            );

            dispatch({
                type: HomeActionType.CHANGE_AVATAR_IMAGE,
                payload: response.data.image,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenAddStudentModal = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenAddModalModalSuccessAction>) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_ADD_STUDENT_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenStudentListModal = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenStudentListModalSuccessAction>) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_STUDENT_LIST_MODEL_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const SetOpenUpdateResearchProjectModal = (value: boolean) => {
    return async (
        dispatch: Dispatch<ISetOpenUpdateResearchProjectModalSuccessAction>
    ) => {
        dispatch({
            type: HomeActionType.SET_OPEN_UPDATE_RESEARCH_PROJECT_MODEL_HOME,
            payload: value,
        });

        return Promise.resolve();
    };
};

export const AddStudent = (model: ICreateStudent) => {
    return async (dispatch: Dispatch<ICreateStudentSuccessAction>) => {
        try {
            instance.post("api/approvement/add-student", model).then((response) => {
                dispatch({
                    type: HomeActionType.CREATE_STUDENT_HOME,
                });

                message.success(response?.data.message);
                return Promise.resolve();
            });
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const AddExcelStudent = (file: File) => {
    return async (dispatch: Dispatch<ICreateStudentsExcelSuccessAction>) => {
        try {
            const formData = new FormData();

            formData.append("file", file);

            instance
                .post("api/approvement/add-student-from-excel", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                })
                .then((response) => {
                    dispatch({
                        type: HomeActionType.CREATE_STUDENTS_EXCEL_HOME,
                        payload: response.data,
                    });

                    message.info(response?.data.goodRows + " студентів успішно додано.");
                    message.info(response?.data.badRows + " студентів не було додано.");
                    return Promise.resolve();
                });
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetStudentList = (page: number, fullName: string = "") => {
    return async (dispatch: Dispatch<IGetStudentListSuccessAction>) => {
        try {
            instance.get(`api/approvement?page=${page}&fullName=${fullName}`).then((response: any) => {
                dispatch({
                    type: HomeActionType.GET_STUDENT_LIST_HOME,
                    payload: response.data,
                });

                return Promise.resolve();
            });
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetApprovementProjectsList = (page: number, projectName: string = "") => {
    return async (dispatch: Dispatch<IGetApprovementProjectSuccessAction>) => {
        try {
            instance.get(`api/verify?page=${page}&projectName=${projectName}`).then((response: any) => {
                dispatch({
                    type: HomeActionType.GET_PROJECT_APPROVEMENT_LIST_HOME,
                    payload: response.data,
                });

                return Promise.resolve();
            });
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetUpdateResearchProject = (id: string) => {
    return async (dispatch: Dispatch<IGetResearchProjectDataSuccessAction>) => {
        try {
            instance
                .get("api/research-project/get-update-load-data?id=" + id)
                .then((response: any) => {
                    dispatch({
                        type: HomeActionType.GET_RESEARCH_PROJECT_DATA_HOME,
                        payload: response.data,
                    });

                    return Promise.resolve();
                });
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const UpdateResearchProject = (data: IUpdateResearchProject) => {
    return async (dispatch: Dispatch<IUpdateResearchProjectSuccessAction>) => {
        const formData = new FormData();

        formData.append("id", data.id);
        formData.append("name", data.name);
        formData.append("description", data.description)
        formData.append("categoryName", data.categoryName);
        formData.append("type", data.type.value);
        formData.append("tags", JSON.stringify(data.tags));
        formData.append("reCaptchaToken", data.reCaptchaToken);

        if (data.file != null) {
            formData.append("file", data.file as any);
        }

        instance
            .put("api/research-project", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    withCredentials: true,
                },
            })
            .then((response: any) => {
                dispatch({
                    type: HomeActionType.UPDATE_RESEARCH_PROJECT_HOME,
                    payload: data.id,
                });

                message.success(response.data.message);
                return Promise.resolve();
            }).catch((err: any) => {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        });
    };
};

export const SetDetailsProjectEmpty = () => {
    return async (dispatch: Dispatch<ISetDetailsProjectEmptySuccessAction>) => {
        dispatch({
            type: HomeActionType.SET_DETAILS_PROJECT_HOME,
        });

        return Promise.resolve();
    };
};
