import '../../css/components/containers/layout.css';

import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {lazy, Suspense, useEffect} from "react";

import {useMediaQuery} from "react-responsive";
import MainHeader from "../header/header";
import Sider from "../sider/sider";
import MainContent from "../content/content";

const CreateResearchProject = lazy(() => import("../modals/createResearchProject"));
const StudentPromote = lazy(() => import("../modals/studentPromote"));
const UsersManageList = lazy(() => import("../modals/usersManageList"));
const UpdateCategory = lazy(() => import("../modals/updateCategory"));
const AddStudent = lazy(() => import("../modals/addStudent"));
const StudentList = lazy(() => import("../modals/studentList"));
const ProjectApprovement = lazy(() => import("../modals/projectApprovement"));
const UpdateResearchProject = lazy(() => import("../modals/updateResearchProject"));
const SiderFilters = lazy(() => import("../sider/siderFilters"));

const StandardLayout = () => {
    const desktopXtablet = useMediaQuery({minWidth: 768});
    const smartphone = useMediaQuery({maxWidth: 767});

    useEffect(() => {
        document.title = "Головна | РФК НУБіП";
    }, []);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
        >
            {desktopXtablet && (<div className="layout-wrapper">
                <MainHeader/>
                <div className="layout-sider-content">
                    <div className="layout-sider">
                        <Sider/>
                    </div>
                    <main className="layout-content">
                        <MainContent/>
                    </main>
                </div>
            </div>)}

            {smartphone && (<div className="layout-wrapper">
                <MainHeader/>
                <Sider/>
                <MainContent/>
            </div>)}

            <Suspense fallback={<></>}>
                <SiderFilters/>
            </Suspense>
            <Suspense fallback={<></>}>
                <CreateResearchProject/>
            </Suspense>
            <Suspense fallback={<></>}>
                <StudentPromote/>
            </Suspense>
            <Suspense fallback={<></>}>
                <UsersManageList/>
            </Suspense>
            <Suspense fallback={<></>}>
                <UpdateCategory/>
            </Suspense>
            <Suspense fallback={<></>}>
                <AddStudent/>
            </Suspense>
            <Suspense fallback={<></>}>
                <StudentList/>
            </Suspense>
            <Suspense fallback={<></>}>
                <ProjectApprovement/>
            </Suspense>
            <Suspense fallback={<></>}>
                <UpdateResearchProject/>
            </Suspense>
        </GoogleReCaptchaProvider>
    );
};

export default StandardLayout;
