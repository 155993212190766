import '../../css/components/home/menus/sider.css';

import Meta from "antd/es/card/Meta";

import {Card, Empty} from "antd";
import {useEffect, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {LoadingOutlined} from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import {Link} from "react-router-dom";
import {useActions} from "../../hooks/redux/useActions";
import {useTypedSelector} from "../../hooks/redux/useTypedSelector";
import ShowAvatar from "../common/avatarImage/avatar";

const Sider = () => {
    const {
        GetResearchProjects,
        GetClearResearchProjects,
    } = useActions();
    const {siderData, searchParams} =
        useTypedSelector((x) => x.home);
    const [page, setPage] = useState(1);
    const [loadedSiderData, setLoadedSiderData] = useState<boolean>();

    useEffect(() => {
        GetResearchProjects(page);
    }, [page]);

    const fetchNextPage = async () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        setLoadedSiderData(false);
        GetClearResearchProjects(
            searchParams.pageIndex,
            searchParams.categoryId,
            searchParams.query,
            searchParams.year,
            searchParams.fullName,
            searchParams.type,
            searchParams.tags,
            searchParams.rejectedProjects,
            searchParams.ownProjects,
        );
        setLoadedSiderData(true);
    }, [searchParams]);

    return (
        <>
            {siderData.items.length > 0 && (
                <div id="scrollableDiv" className="section-sider hide-scrollbar">
                    <InfiniteScroll
                        className="infinite-scroll-sider"
                        dataLength={siderData.pageIndex * siderData.pageSize}
                        next={fetchNextPage}
                        hasMore={siderData.hasNextPage}
                        loader={<Paragraph className="text-scroll">Завантаження...</Paragraph>}
                        scrollableTarget="scrollableDiv"
                        endMessage={<p className="text-scroll">Вітаю, ви догортали до кінця!</p>}
                    >
                        {siderData.items.map((value, key) => (
                            <Link to={"/home/" + value.id} className="card-list-sider-link" key={key}>
                                <Card
                                    className="card-list-sider noselect"
                                    key={key}
                                    hoverable
                                >
                                    <Meta
                                        key={key}
                                        avatar={<ShowAvatar value={value.authorAvatar} key={key}/>}
                                        title={value.name}
                                        description={value.author}
                                    />
                                </Card>
                            </Link>
                        ))}
                    </InfiniteScroll>
                </div>)}
            {siderData.items.length === 0 && loadedSiderData && (
                <div className="sider-list">
                    <Empty
                        className="sider-empty-logo noselect"
                        description="Наукові роботи відсутні"
                    />
                </div>
            )}
            {siderData.items.length === 0 && !loadedSiderData && (
                <div className="sider-list">
                    <LoadingOutlined className="sider-loading-logo"/>
                </div>
            )}
        </>
    );
};

export default Sider;
