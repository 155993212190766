import {rootReducer} from './reducers';
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'main-root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);
const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

const store = configureStore({
    middleware: customizedMiddleware,
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_NODE_ENV !== "production",
});

const Persistor = persistStore(store);

export {Persistor};
export default store;