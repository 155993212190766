import {message} from "antd";
import {Dispatch} from "react";
import {
    IRecoveryConfirmPassoword,
    IRecoveryPassowordConfirmSuccessAction,
    IRecoveryPassowordSuccessAction,
    IRecoveryPassword
} from "../../../types/auth/passwordRecoveryTypes";
import axios from "axios";
import {AuthActionType} from "../../../types/stores/authStoreTypes";

export const RecoveryPassword = (data: IRecoveryPassword) => {
    return async (dispatch: Dispatch<IRecoveryPassowordSuccessAction>) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "api/auth/recover-password",
                data
            );
            dispatch({
                type: AuthActionType.RECOVERY_PASSWORD,
            });

            message.success(response.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const RecoveryPasswordConfirm = (data: IRecoveryConfirmPassoword) => {
    return async (dispatch: Dispatch<IRecoveryPassowordConfirmSuccessAction>) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "api/auth/confirm-password",
                data
            );
            dispatch({
                type: AuthActionType.RECOVERY_PASSWORD_CONFIRM,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};
