import '../../css/components/style/quill.snow.css';
import '../../css/components/home/menus/privateDetails.css';

import rcNubip from "../../assets/logos/rcnubip-logo.png";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {Typography} from "antd";
import ReactQuill from "react-quill";
import {useActions} from "../../hooks/redux/useActions";
import {useTypedSelector} from "../../hooks/redux/useTypedSelector";

const PrivateDetails = () => {
    const {id} = useParams();
    const {GetShortInfoByIdResearchProject} = useActions();
    const {privateDetails} = useTypedSelector(x => x.auth);

    useEffect(() => {
        GetShortInfoByIdResearchProject(id as string);
    }, []);

    return (
        <section className="private-details-wrapper hide-scrollbar">
            <div className="private-details-bg noselect">
                <div className="private-details-college-wrapper">
                    <div>
                        <img
                            src={rcNubip}
                            className="private-details-college-logo"
                            alt="College logo"
                        />
                    </div>
                    <div>
                        <h1 className="private-details-college-title">
                            ВСП "РФК НУБіП України"
                        </h1>
                    </div>
                </div>
                <h2 className="private-details-subtitle">Наукова робота</h2>
                <Typography className="private-details-name">{privateDetails.name}</Typography>
                <br/>
                <ReactQuill
                    value={privateDetails.description}
                    className="private-details-description"
                    readOnly={true}
                    theme="bubble"
                />
                <h2 className="private-details-copyright">Copyright &copy;2022-{new Date().getFullYear()} RC NUBIP of
                    Ukraine. All rights reserved</h2>
            </div>
        </section>
    );
}

export default PrivateDetails;
