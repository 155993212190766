import axios from "axios";

import {
    IDeleteCategorySuccessAction,
    IGetAuthorsListSuccessAction,
    IGetCategoriesSuccessAction,
    IGetClearResearchProjectsSuccessAction,
    IGetResearchProjectsSuccessAction,
    IGetSetResearchProjectsSuccessAction,
    IGetYearsListSuccessAction,
    ISetFiltersSuccessAction,
    ISetOpenSectionSiderFiltersMenuSuccessAction,
    ISetSiderQuerySuccessAction,
} from "../../../types/home/siderTypes";
import {Dispatch} from "react";
import {message} from "antd";
import {
    HomeActionType,
    ICategoryResearchProject,
    IResearchProject,
    ISearchParams
} from "../../../types/stores/homeStoreTypes";
import instance from "../../../api/configuration/configure";

export const GetCategories = () => {
    return async (dispatch: Dispatch<IGetCategoriesSuccessAction>) => {
        try {
            const response = await instance.get("api/category");
            dispatch({
                type: HomeActionType.GET_CATEGORIES_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetYears = () => {
    return async (dispatch: Dispatch<IGetYearsListSuccessAction>) => {
        try {
            const response = await instance.get("api/research-project/years-list");
            dispatch({
                type: HomeActionType.GET_YEARS_LIST_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const GetAuthors = (fullName: string) => {
    return async (dispatch: Dispatch<IGetAuthorsListSuccessAction>) => {
        try {
            if (fullName === "") {
                return;
            }

            const response = await instance.get(
                "api/auth/users-list?fullName=" + fullName
            );
            dispatch({
                type: HomeActionType.GET_AUTHORS_LIST_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const SetFilters = (data: ISearchParams) => {
    return async (dispatch: Dispatch<ISetFiltersSuccessAction>) => {
        dispatch({
            type: HomeActionType.SET_FILTERS_HOME,
            payload: data,
        });

        return Promise.resolve();
    };
};

export const SetOpenSectionSiderFiltersMenu = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenSectionSiderFiltersMenuSuccessAction>) => {
        dispatch({
            type: HomeActionType.SET_OPEN_SECTION_SIDER_FILTERS_MENU,
            payload: value,
        });

        return Promise.resolve();
    };
};

export const GetResearchProjects = (
    pageIndex: number = 1,
    categoryId: string | null = null,
    projectName: string | null = null,
    year: string | null = null,
    fullName: string | null = null,
    type: number | null = null,
    tags: string[] | null = null
) => {
    return (dispatch: Dispatch<IGetResearchProjectsSuccessAction>) => {
        instance
            .get("api/research-project", {
                params: {
                    categoryId: categoryId,
                    pageIndex: pageIndex,
                    projectName: projectName,
                    year: year,
                    fullName: fullName,
                    type: type,
                    tags: tags,
                },
            })
            .then((response: any) => {
                dispatch({
                    type: HomeActionType.GET_RESEARCH_PROJECTS_HOME,
                    payload: response.data,
                });

                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    };
};

export const GetClearResearchProjects = (
    pageIndex: number = 1,
    categoryId: string | null = null,
    projectName: string | null = null,
    year: string | null = null,
    fullName: string | null = null,
    type: number | null = null,
    tags: string[] | null = null,
    rejectedProjects: boolean | string = "",
    ownProjects: boolean = false,
) => {
    return (dispatch: Dispatch<IGetClearResearchProjectsSuccessAction>) => {
        instance
            .get("api/research-project", {
                params: {
                    categoryId: categoryId,
                    pageIndex: pageIndex,
                    projectName: projectName,
                    year: year,
                    fullName: fullName,
                    type: type,
                    tags: tags,
                    rejectedProjects: rejectedProjects,
                    ownProjects: ownProjects,
                },
            })
            .then((response: any) => {
                let responseData = response.data;
                if (response.status === 204 && response.data === "") {
                    responseData = {
                        items: [] as Array<IResearchProject>,
                        totalCount: 0,
                        pageIndex: 0,
                        pageSize: 0,
                        totalPages: 0,
                        hasPreviousPage: false,
                        hasNextPage: false,
                    } as ICategoryResearchProject;
                }

                dispatch({
                    type: HomeActionType.GET_CLEAR_RESEARCH_PROJECTS_HOME,
                    payload: responseData,
                });

                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    };
};

export const GetSetResearchProjects = (
    pageIndex: number = 1,
    categoryId: string | null = null,
    projectName: string | null = null,
    year: string | null = null,
    fullName: string | null = null,
    type: number | null = null,
    tags: string[] | null = null,
    rejectedProjects: boolean | string = "",
    ownProjects: boolean = false,
) => {
    return (dispatch: Dispatch<IGetSetResearchProjectsSuccessAction>) => {
        const temp = JSON.stringify(tags).replace("%22", '"');
        instance
            .get("api/research-project", {
                params: {
                    categoryId: categoryId,
                    pageIndex: pageIndex,
                    projectName: projectName,
                    year: year,
                    fullName: fullName,
                    type: type,
                    tags: temp,
                    rejectedProjects: rejectedProjects,
                    ownProjects: ownProjects,
                },
            })
            .then((response: any) => {
                let responseData = response.data;
                if (response.status === 204 && response.data === "") {
                    responseData = {
                        items: [] as Array<IResearchProject>,
                        totalCount: 0,
                        pageIndex: 0,
                        pageSize: 0,
                        totalPages: 0,
                        hasPreviousPage: false,
                        hasNextPage: false,
                    } as ICategoryResearchProject;
                }

                dispatch({
                    type: HomeActionType.GET_SET_RESEARCH_PROJECTS_HOME,
                    payload: responseData,
                });

                return Promise.resolve();
            })
            .catch(() => {
                return Promise.reject();
            });
    };
};

export const SetSiderQuery = (value: string | null) => {
    return async (dispatch: Dispatch<ISetSiderQuerySuccessAction>) => {
        dispatch({
            type: HomeActionType.SET_SIDER_QUERY_HOME,
            payload: value,
        });

        return Promise.resolve();
    };
};

export const DeleteCategory = (categoryId: string) => {
    return async (dispatch: Dispatch<IDeleteCategorySuccessAction>) => {
        try {
            const response = await instance.delete("api/category/" + categoryId);

            dispatch({
                type: HomeActionType.DELETE_CATEGORY_HOME,
                payload: categoryId,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};
