import {Navigate, Route, Routes} from "react-router-dom";
import {useTypedSelector} from "./hooks/redux/useTypedSelector";
import PrivateRoute from "./components/common/routes/privateRoute";
import PublicRoute from "./components/common/routes/publicRoute";
import PasswordRecoveryConfirmation from "./components/auth/passwordRecovery/passwordRecoveryConfirmation";
import PasswordRecovery from "./components/auth/passwordRecovery/passwordRecovery";
import EmailInfoSend from "./components/auth/email/emailInfoSend";
import Register from "./components/auth/register/register";
import EmailConfirmation from "./components/auth/email/emailConfirmation";
import Login from "./components/auth/login/login";
import PrivateDetails from "./components/privateDetails/privateDetails";
import AuthLayout from "./components/containers/authLayout";
import PasswordRecoveryConfirmationResult from "./components/auth/passwordRecovery/passwordRecoveryConfirmationResult";
import StandardLayout from "./components/containers/standardLayout";

const App = () => {
    const {isAuth} = useTypedSelector((state) => state.auth);

    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to={false ? "/home" : "/login"}/>}
            />

            <Route path="/" element={<AuthLayout/>}>
                <Route
                    index
                    path="/:id"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PrivateDetails/>
                        </PublicRoute>
                    }
                />
                <Route
                    index
                    path="/login"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <Login/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <Register/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/confirm-email"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <EmailConfirmation/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/send-email"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <EmailInfoSend/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PasswordRecovery/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery-confirm"
                    element={
                        <PublicRoute isAuth={isAuth}>
                            <PasswordRecoveryConfirmation/>
                        </PublicRoute>
                    }
                />
                <Route
                    path="/password-recovery-confirm-result"
                    element={<PasswordRecoveryConfirmationResult/>}
                />
            </Route>

            <Route
                index
                path="/home"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout/>
                    </PrivateRoute>
                }
            />

            <Route
                index
                path="/home/:id"
                element={
                    <PrivateRoute isAuth={isAuth}>
                        <StandardLayout/>
                    </PrivateRoute>
                }
            />
        </Routes>
    );
};

export default App;
