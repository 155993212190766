import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Result} from "antd";

const PasswordRecoveryConfirmationResult = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Підтвердження відновлення паролю | РФК НУБіП";

        setTimeout(() => {
            navigate("/login");
        }, 3500);
    }, []);

    return (
        <section
            style={{
                position: "fixed",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <Result
                status="success"
                title="Підтвердження відновлення паролю"
                subTitle="Дякуємо, ваш пароль успішно змінено!"
            />
        </section>
    );
};

export default PasswordRecoveryConfirmationResult;
