import '../../../css/components/auth/register.css';

import rcNubip from "../../../assets/logos/rcnubip-logo.png";
import {Button, Form, Input} from "antd";
import {Link, useNavigate} from "react-router-dom";
import GoogleAuth, {IAuthProvider} from "../../common/googleAuth/googleAuth";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";
import {ICreateRegister, IRegister} from "../../../types/auth/registerTypes";
import {useEffect} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useActions} from "../../../hooks/redux/useActions";
import Paragraph from "antd/es/typography/Paragraph";

const Register = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const {RegisterUser, GoogleRegisterUser, GoogleOneTapRegisterUser} =
        useActions();

    useEffect(() => {
        document.title = "Реєстрація | РФК НУБіП";
    }, []);

    const onFinish = async (values: IRegister) => {
        if (!executeRecaptcha) {
            return;
        }

        const reCaptchaToken = await executeRecaptcha();
        const model: ICreateRegister = {
            ...values,
            reCaptchaToken: reCaptchaToken,
        };

        const result = await RegisterUser(model);
        if ((result as boolean | unknown) === true) {
            setTimeout(() => {
                navigate("/send-email");
            }, 2000);
        }
    };

    return (
        <section className="section-register hide-scrollbar">
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
                scrollToFirstError
            >
                <div className="bg-form-register">
                    <div className="form-wrapper-register">
                        <div className="form-center-div-register">
                            <div>
                                <img
                                    src={rcNubip}
                                    className="form-rc-nubip-logo-register"
                                    alt="College logo"
                                />
                            </div>
                            <div>
                                <h1 className="form-rc-nubip-title-register">
                                    ВСП "РФК НУБіП України"
                                </h1>
                            </div>
                        </div>
                        <h1 className="form-title-register">Репозитарій | Реєстрація</h1>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    message: "Не вірний формат поштової скриньки!",
                                },
                                {
                                    required: true,
                                    message: "Будь ласка введіть поштову скриньку!",
                                },
                            ]}
                            className="form-item-register"
                            hasFeedback
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon"/>}
                                className="form-input-register"
                                placeholder="Введіть поштову скриньку"
                            />
                        </Form.Item>
                        <Form.Item
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: "Будь ласка введіть ім'я!",
                                },
                            ]}
                            className="form-item-register"
                            hasFeedback
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                   className="form-input-register" placeholder="Введіть ім'я"/>
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[
                                {
                                    required: true,
                                    message: "Будь ласка введіть прізвище!",
                                },
                            ]}
                            className="form-item-register"
                            hasFeedback
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                   className="form-input-register" placeholder="Введіть прізвище"/>
                        </Form.Item>
                        <Form.Item
                            name="middleName"
                            rules={[
                                {
                                    required: true,
                                    message: "Будь ласка введіть по батькові!",
                                },
                            ]}
                            className="form-item-register"
                            hasFeedback
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon"/>}
                                   className="form-input-register" placeholder="Введіть по батькові"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {required: true, message: "Будь ласка введіть пароль!"},
                                {
                                    min: 8,
                                    message: "Пароль повинен мати щонайменше 8 символів!",
                                },
                            ]}
                            className="form-item-register"
                            hasFeedback
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                className="form-input-register"
                                placeholder="Введіть пароль"
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={["password"]}
                            className="form-item-register"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Будь ласка введіть пароль!",
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Ваші паролі не співпадають!")
                                        );
                                    },
                                }),
                                {
                                    min: 8,
                                    message: "Пароль повинен мати щонайменше 8 символів!",
                                },
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                className="form-input-register"
                                placeholder="Введіть пароль повторно"
                                iconRender={(visible: any) =>
                                    visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                                }
                            />
                        </Form.Item>
                        <Paragraph className="form-password-rule-register">
                            *Пароль повинен містити принаймні 8 символів, включаючи малі та великі літери, а також
                            цифри.
                        </Paragraph>
                        <Form.Item className="form-item-register">
                            <Button type="primary" className="form-btn-submit-register" htmlType="submit">
                                Реєстрація
                            </Button>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <GoogleAuth
                                handleGoogleOneTapSuccess={(response: any) => {
                                    const {credential} = response;

                                    GoogleRegisterUser({
                                        provider: "Google",
                                        token: credential,
                                    } as IAuthProvider);

                                    // setTimeout(() => {
                                    //     navigate("/login");
                                    // }, 500);
                                }}
                                handleGoogleSuccess={(response: any) => {
                                    GoogleOneTapRegisterUser({
                                        provider: "Google",
                                        token: response.access_token,
                                    } as IAuthProvider);

                                    // setTimeout(() => {
                                    //     navigate("/login");
                                    // }, 500);
                                }}
                                text="Зареєструватись за допомогою Google"
                            />
                            <div className="form-under-title-register">
                                <label>Вже маєте акаунт? </label>
                                <Link to="/login">
                                    Вхід
                                </Link>
                            </div>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </section>
    );
};

export default Register;
