import '../../../css/components/auth/emailInfoSend.css';
import {Result} from "antd";

const EmailInfoSend = () => {
    return (
        <section className="center-email-section">
            <Result
                status="info"
                title="Лист надіслано"
                subTitle="Будь ласка підтвердіть вашу поштову скриньку за посиланням, що надійде до неї!"
            />
        </section>
    );
};

export default EmailInfoSend;