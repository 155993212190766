import {IConfirmEmail, IConfirmEmailSuccessAction} from "../../../types/auth/emailTypes";
import axios from "axios";
import {message} from "antd";
import {Dispatch} from "react";
import {AuthActionType} from "../../../types/stores/authStoreTypes";

export const EmailConfirm = (data: IConfirmEmail) => {
    return async (dispatch: Dispatch<IConfirmEmailSuccessAction>) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_SERVER_URL + "api/auth/confirm-email",
                data
            );
            dispatch({
                type: AuthActionType.CONFIRM_EMAIL_AUTH,
            });

            message.success(response?.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};