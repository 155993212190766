export interface ICategory {
    id: string;
    name: string;
}

export interface IResearchProject {
    id: string;
    name: string;
    author: string;
    authorAvatar: string;
}

export interface ICreateResearchProject {
    id: string;
    name: string;
    description: string;
    categoryName: string;
    type: string;
    tags: Array<string>;
    reCaptchaToken: string;
}

export interface ICreateCategory {
    name: string;
    reCaptchaToken: string;
}

export interface IUpdateCategory {
    id: string;
    name: string;
    reCaptchaToken: string;
}

export interface IUpdateCategoryData {
    id: string;
    name: string;
}

export interface ICategoryResearchProject {
    items: Array<IResearchProject>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface IApprovementStudent {
    key: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    studentCode: string;
}

export interface IApprovementProject {
    id: string;
    name: string;
    description: string;
    fileName: string;
    coverImageName: string;
    dateCreated: string;
    avatarImage: string;
    fullName: string;
    categoryName: string;
    tags: Array<string>;
}

export interface IGetApprovementStudent {
    items: Array<IApprovementStudent>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface IGetApprovementProject {
    items: Array<IApprovementProject>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface IGetResearchProjectDetails {
    id: string;
    name: string;
    fileName: string;
    coverImageName: string;
    description: string;
    categoryName: string;
    author: string;
    authorAvatar: string;
    dateCreated: string;
    dateEdited: string;
    type: number;
    tags: Array<string>,
    isVerified: boolean | null,
}

export interface IGetStudentFromExcel {
    goodRows: number;
    badRows: number;
}

export interface IGetUpdateProjectLoadData {
    id: string;
    name: string;
    description: string;
    categoryName: string;
    type: number;
    tags: Array<string>;
}

export interface IUpdateResearchProject {
    id: string;
    name: string;
    description: string;
    categoryName: string;
    type: any;
    tags: Array<string>;
    file: File | null;
    reCaptchaToken: string;
}

export interface IUpdateResearchProjectResponse {
    name: string;
    description: string;
    categoryName: string;
    fileName: string;
    coverImageName: string;
    type: number;
    tags: Array<string>;
    dateEdited: string;
}

export interface IUserManageList {
    id: string;
    avatar: string;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    currentRole: string;
}

export interface IGetUserManageList {
    items: Array<IUserManageList>;
    totalCount: number;
    pageIndex: number;
    pageSize: number;
    totalPages: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface HomeState {
    categories: Array<ICategory>;
    yearsList: Array<string>;
    authorsList: Array<string>;
    siderData: ICategoryResearchProject;
    usersManageList: IGetUserManageList;
    isOpenSectionSiderFiltersMenu: boolean;
    isOpenSectionHeaderMenu: boolean;
    isOpenCreateProjectModal: boolean;
    isOpenUsersManageListModal: boolean;
    isOpenEditCategoryModal: boolean;
    isOpenStudentPromoteModal: boolean;
    isOpenUpdateCategoryModal: boolean;
    isOpenRoleModal: boolean;
    isOpenAddStudentModal: boolean;
    isOpenStudentListModal: boolean;
    isOpenProjectApprovementModal: boolean;
    isOpenUpdateResearchProjectModal: boolean;
    createResearchProjectData: string;
    details: IGetResearchProjectDetails;
    excelAddResponse: IGetStudentFromExcel;
    updateCategoryData: IUpdateCategoryData;
    updateProjectData: IGetUpdateProjectLoadData;
    searchParams: ISearchParams;
    registeredUsers: number;
    registeredCategories: number;
    registeredVerifiedProjects: number;
    registeredUnderReviewProjects: number;
    emailUserList: Array<string>;
    roleList: Array<string>;
    studentList: IGetApprovementStudent;
    projectApprovementList: IGetApprovementProject;
}

export interface ISearchParams {
    query: string | null;
    pageIndex: number;
    year: string | null;
    categoryId: string | null;
    fullName: string | null;
    type: number | null;
    tags: Array<string>;
    rejectedProjects: boolean | string;
    ownProjects: boolean;
}

export enum HomeActionType {
    GET_USERS_MANAGE_LIST_HOME = "HOME/GET_USERS_MANAGE_LIST_HOME",
    SET_SIDER_QUERY_HOME = "HOME/SET_SIDER_QUERY_HOME",
    GET_CATEGORIES_HOME = "HOME/GET_CATEGORIES_HOME",
    GET_TAGS_HOME = "HOME/GET_TAGS_HOME",
    DELETE_FROM_LIST_BY_ID_HOME = "HOME/DELETE_FROM_LIST_BY_ID_HOME",
    GET_STUDENT_LIST_HOME = "HOME/GET_STUDENT_LIST_HOME",
    GET_RESEARCH_PROJECT_DATA_HOME = "HOME/GET_RESEARCH_PROJECT_DATA_HOME",
    GET_PROJECT_APPROVEMENT_LIST_HOME = "HOME/GET_PROJECT_APPROVEMENT_LIST_HOME",
    GET_YEARS_LIST_HOME = "HOME/GET_YEARS_LIST_HOME",
    GET_AUTHORS_LIST_HOME = "HOME/GET_AUTHORS_LIST_HOME",
    SET_FILTERS_HOME = "HOME/SET_FILTERS_HOME",
    CREATE_STUDENT_HOME = "HOME/CREATE_STUDENT_HOME",
    CREATE_STUDENTS_EXCEL_HOME = "HOME/CREATE_STUDENTS_EXCEL_HOME",
    UPDATE_RESEARCH_PROJECT_HOME = "HOME/UPDATE_RESEARCH_PROJECT_HOME",
    GET_CLEAR_RESEARCH_PROJECTS_HOME = "HOME/GET_CLEAR_RESEARCH_PROJECTS_HOME",
    GET_RESEARCH_PROJECTS_HOME = "HOME/GET_RESEARCH_PROJECTS_HOME",
    GET_SET_RESEARCH_PROJECTS_HOME = "HOME/GET_SET_RESEARCH_PROJECTS_HOME",
    SET_OPEN_SECTION_SIDER_FILTERS_MENU = "HOME/SET_OPEN_SECTION_SIDER_FILTERS_MENU",
    SET_OPEN_USERS_MANAGE_LIST_MENU_H0ME = "HOME/SET_OPEN_USERS_MANAGE_LIST_MENU_HOME",
    SET_OPEN_SECTION_HEADER_MENU_OPEN_HOME = "HOME/SET_OPEN_SECTION_HEADER_MENU_OPEN_HOME",
    SET_OPEN_CREATE_PROJECT_MODAL_HOME = "HOME/SET_OPEN_CREATE_PROJECT_MODAL_HOME",
    SET_OPEN_EDIT_CATEGORY_HOME = "HOME/SET_OPEN_EDIT_CATEGORY_HOME",
    SET_OPEN_STUDENT_PROMOTE_HOME = "HOME/SET_OPEN_STUDENT_PROMOTE_HOME",
    SET_OPEN_ROLE_HOME = "HOME/SET_OPEN_ROLE_HOME",
    SET_OPEN_ADD_STUDENT_HOME = "HOME/SET_OPEN_ADD_STUDENT_HOME",
    SET_OPEN_UPDATE_CATEGORY_HOME = "HOME/SET_OPEN_UPDATE_CATEGORY_HOME",
    SET_OPEN_STUDENT_LIST_MODEL_HOME = "HOME/SET_OPEN_STUDENT_LIST_MODEL_HOME",
    SET_OPEN_PROJECT_APPROVEMENT_MODEL_HOME = "HOME/SET_OPEN_PROJECT_APPROVEMENT_MODEL_HOME",
    SET_OPEN_UPDATE_RESEARCH_PROJECT_MODEL_HOME = "HOME/SET_OPEN_UPDATE_RESEARCH_PROJECT_MODEL_HOME",
    SET_IMAGE_CREATE_PROJECT_MODEL = "HOME/SET_IMAGE_CREATE_PROJECT_MODEL",
    SET_FILE_CREATE_PROJECT_MODEL = "HOME/SET_FILE_CREATE_PROJECT_MODEL",
    CREATE_RESEARCH_PROJECT_HOME = "HOME/CREATE_RESEARCH_PROJECT_HOME",
    CREATE_CATEGORY_HOME = "HOME/CREATE_CATEGORY_HOME",
    GET_RESEARCH_PROJECT_DETAILS_HOME = "HOME/GET_RESEARCH_PROJECT_DETAILS_HOME",
    DELETE_CATEGORY_HOME = "HOME/DELETE_CATEGORY_HOME",
    UPDATE_CATEGORY_HOME = "HOME/UPDATE_CATEGORY_HOME",
    UPDATE_CATEGORY_DATA_HOME = "HOME/UPDATE_CATEGORY_DATA_HOME",
    STUDENT_PROMOTE_HOME = "HOME/STUDENT_PROMOTE_HOME",
    REGISTERED_USERS_HOME = "HOME/REGISTERED_USERS_HOME",
    REGISTERED_CATEGORIES_HOME = "HOME/REGISTERED_CATEGORIES_HOME",
    REGISTERED_VERIFIED_RESEARCH_PROJECTS_HOME = "HOME/REGISTERED_VERIFIED_RESEARCH_PROJECTS_HOME",
    REGISTERED_UNDER_REVIEW_RESEARCH_PROJECTS_HOME = "HOME/REGISTERED_UNDER_REVIEW_RESEARCH_PROJECTS_HOME",
    GET_EMAIL_USER_LIST_HOME = "HOME/GET_EMAIL_USER_LIST_HOME",
    GET_ROLE_LIST_HOME = "HOME/GET_ROLE_LIST_HOME",
    SET_USER_ROLE_HOME = "HOME/GET_ROLE_LIST_HOME",
    SET_EMPTY_MEDIA_FIELDS = "HOME/SET_EMPTY_MEDIA_FIELDS",
    CHANGE_AVATAR_IMAGE = "HOME/CHANGE_AVATAR_IMAGE",
    SET_DETAILS_PROJECT_HOME = "HOME/SET_DETAILS_PROJECT_HOME",
}
