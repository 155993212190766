import {Navigate} from "react-router-dom";

const PublicRoute = ({isAuth, children}: any) => {
    if (isAuth) {
        return <Navigate to="/home"/>;
    }

    return children;
};

export default PublicRoute;
