import {message} from "antd";
import {Dispatch} from "react";
import {ICreateLogin, ILoginSuccessAction, ILogoutSuccessAction} from "../../../types/auth/loginTypes";
import axios from "axios";
import instance from "../../../api/configuration/configure";
import {jwtDecode} from "jwt-decode";
import {AuthActionType, IUser} from "../../../types/stores/authStoreTypes";
import {IAuthProvider} from "../../../components/common/googleAuth/googleAuth";

export const LoginUser = (data: ICreateLogin) => {
    return async (dispatch: Dispatch<ILoginSuccessAction>) => {
        axios
            .post(process.env.REACT_APP_SERVER_URL + "api/auth/login", data)
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken);

                const userData = jwtDecode<any>(response.data.token);
                const user: IUser = {
                    id: userData.id,
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email:
                        userData[
                            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                            ],
                    userName: userData.userName,
                    avatarImage: userData.avatarImage,
                    roles:
                        userData[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                            ],
                };

                dispatch({
                    type: AuthActionType.LOGIN_AUTH,
                    payload: user,
                });

                message.success(response?.data.message);
                return Promise.resolve();
            })
            .catch((error) => {
                if (axios.isAxiosError(error)) {
                    message.error(error.response?.data.message);
                }

                return Promise.reject();
            });
    };
};

export const GoogleLoginUser = (data: IAuthProvider) => {
    return async (dispatch: Dispatch<ILoginSuccessAction>) => {
        axios
            .post(process.env.REACT_APP_SERVER_URL + "api/auth/google-login", data)
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken);

                const userData = jwtDecode<any>(response.data.token);
                const user: IUser = {
                    id: userData.id,
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email:
                        userData[
                            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                            ],
                    userName: userData.userName,
                    avatarImage: userData.avatarImage,
                    roles:
                        userData[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                            ],
                };

                dispatch({
                    type: AuthActionType.LOGIN_AUTH,
                    payload: user,
                });

                message.success(response.data.message);
                return Promise.resolve();
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    message.error(err.response?.data.message);
                }

                return Promise.reject();
            });
    };
};

export const GoogleOneTapLoginUser = (data: IAuthProvider) => {
    return async (dispatch: Dispatch<ILoginSuccessAction>) => {
        axios
            .post(
                process.env.REACT_APP_SERVER_URL + "api/auth/google-one-tap-login",
                data
            )
            .then((response) => {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken);

                const userData = jwtDecode<any>(response.data.token);
                const user: IUser = {
                    id: userData.id,
                    firstName: userData.firstName,
                    middleName: userData.middleName,
                    lastName: userData.lastName,
                    email:
                        userData[
                            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
                            ],
                    userName: userData.userName,
                    avatarImage: userData.avatarImage,
                    roles:
                        userData[
                            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
                            ],
                };

                dispatch({
                    type: AuthActionType.LOGIN_AUTH,
                    payload: user,
                });

                message.success(response.data.message);
                return Promise.resolve();
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    message.error(err.response?.data.message);
                }

                return Promise.reject();
            });
    };
};

export const setAuthUserByToken = (token: string, dispatch: Dispatch<any>) => {
    const userData = jwtDecode<any>(token as string);
    const user: IUser = {
        id: userData.id,
        firstName: userData.firstName,
        middleName: userData.middleName,
        lastName: userData.lastName,
        email:
            userData["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
        userName: userData.userName,
        avatarImage: userData.avatarImage,
        roles:
            userData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
    };

    dispatch({
        type: AuthActionType.RELOGIN_AUTH,
        payload: user,
    });
};

export const LogOut = () => {
    return async (dispatch: Dispatch<ILogoutSuccessAction>) => {
        try {
            const response = await instance.post(
                process.env.REACT_APP_SERVER_URL + "api/auth/revoke-token",
                {token: localStorage.getItem("refreshToken")}
            );

            dispatch({
                type: AuthActionType.LOGOUT_AUTH,
            });

            message.success(response.data.message);
            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};

export const LogOutSpecial = () => {
    return async (dispatch: Dispatch<ILogoutSuccessAction>) => {
        try {
            dispatch({
                type: AuthActionType.LOGOUT_AUTH,
            });

            return Promise.resolve();
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return Promise.reject();
        }
    };
};
