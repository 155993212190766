import '../../../css/components/auth/passwordRecovery.css';

import {UserOutlined} from "@ant-design/icons";
import {Button, Form, Input} from "antd";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link} from "react-router-dom";
import {useEffect} from 'react';
import {IRecoveryPassword} from "../../../types/auth/passwordRecoveryTypes";
import {useActions} from "../../../hooks/redux/useActions";

const PasswordRecovery = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {RecoveryPassword} = useActions();

    useEffect(() => {
        document.title = "Відновлення паролю | РФК НУБіП";
    }, []);

    const onFinish = async (values: any) => {
        if (!executeRecaptcha) {
            return;
        }

        const reCaptchaToken = await executeRecaptcha();
        const model: IRecoveryPassword = {
            email: values.email,
            reCaptchaToken: reCaptchaToken,
        };

        await RecoveryPassword(model);
    };

    return (
        <section className="section-password-recovery hide-scrollbar">
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
                scrollToFirstError
            >
                <div className="bg-form-password-recovery">
                    <h1 style={{textAlign: "center"}}>Відновлення паролю</h1>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: "email",
                                message: "Не вірний формат поштової скриньки!",
                            },
                            {
                                required: true,
                                message: "Будь ласка введіть вашу поштову скриньку!",
                            },
                        ]}
                        hasFeedback
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            className="form-input-recover-password"
                            placeholder="Введіть поштову скриньку"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="form-btn-submit-recover-password" htmlType="submit">
                            Надіслати
                        </Button>
                        <div className="form-under-title-recover-password">
                            <label>Хочете повернутися? </label>
                            <Link to="/login">
                                Вхід
                            </Link>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </section>
    );
};

export default PasswordRecovery;
