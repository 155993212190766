import {homeReducer} from '../redurers/homeReducer';
import {authReducer} from '../redurers/authReducer';
import {combineReducers} from "redux";

export const rootReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
