import * as RegisterActionCreator from '../actions/auth/registerActions';
import * as LoginActionCreator from '../actions/auth/loginActions';
import * as EmailActionCreator from '../actions/auth/emailActions';
import * as RecoveryPasswordActionCreator from '../actions/auth/recoveryPasswordActions';
import * as ContentActionCreator from '../actions/home/contentActions';
import * as ModalsActionCreator from '../actions/home/modalsActions';
import * as HeaderActionCreator from '../actions/home/headerActions';
import * as SiderActionCreator from '../actions/home/siderActions';
import * as PrivateDetailsActionCreator from '../actions/home/privateDetailsActions';

const actions = {
    ...RegisterActionCreator,
    ...LoginActionCreator,
    ...EmailActionCreator,
    ...RecoveryPasswordActionCreator,
    ...ContentActionCreator,
    ...ModalsActionCreator,
    ...HeaderActionCreator,
    ...SiderActionCreator,
    ...PrivateDetailsActionCreator,
}

export default actions;