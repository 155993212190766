import {AuthActionType, AuthState, IGetShortInfoResearchProject, IUser} from "../../types/stores/authStoreTypes";

const initialState: AuthState = {
    isAuth: false,
    user: {
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        userName: "",
        avatarImage: "",
        roles: new Array<string>(),
    },
    privateDetails: {
        id: "",
        name: "",
        description: "",
    } as IGetShortInfoResearchProject,
}

export const authReducer = (state = initialState, action: any): AuthState => {
    switch (action.type) {
        case AuthActionType.REGISTER_AUTH:
            return {
                ...state,
            }
        case AuthActionType.CONFIRM_EMAIL_AUTH:
            return {
                ...state,
            }
        case AuthActionType.LOGOUT_AUTH:
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("avatarImage");

            return {
                ...state,
                isAuth: false,
                user: {} as IUser,
            }
        case AuthActionType.LOGIN_AUTH:
            localStorage.removeItem("avatarImage");

            return {
                ...state,
                isAuth: true,
                user: {...action.payload},
            }
        case AuthActionType.RELOGIN_AUTH:
            return {
                ...state,
                isAuth: true,
                user: {...action.payload},
            }
        case AuthActionType.GET_SHORT_INFO_RESEARCH_PROJECT_AUTH:
            return {
                ...state,
                privateDetails: action.payload,
            };
    }
    return state;
}
