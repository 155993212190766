import '../../../css/components/auth/login.css';

import rcNubip from "../../../assets/logos/rcnubip-logo.png";
import GoogleAuth, {IAuthProvider} from "../../common/googleAuth/googleAuth";
import {Link, useNavigate} from "react-router-dom";
import {Button, Checkbox, Form, Input} from "antd";
import {ICreateLogin, ILogin} from "../../../types/auth/loginTypes";
import {useEffect} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useActions} from "../../../hooks/redux/useActions";
import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined, UserOutlined} from "@ant-design/icons";

const Login = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const {LoginUser, GoogleLoginUser, GoogleOneTapLoginUser} = useActions();

    useEffect(() => {
        document.title = "Вхід | РФК НУБіП";
    }, []);

    const onFinish = async (values: ILogin) => {
        try {
            if (!executeRecaptcha) {
                return;
            }
            const reCaptchaToken = await executeRecaptcha();
            const model: ICreateLogin = {
                ...values,
                reCaptchaToken: reCaptchaToken,
            };

            LoginUser(model);
        } catch (error) {
            setTimeout(() => {
                navigate("/home");
            }, 2000);
        }
    };

    return (
        <section className="section-login hide-scrollbar">
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
                scrollToFirstError
            >
                <div className="bg-form-login">
                    <div className="form-wrapper-login">
                        <div className="form-center-div-login">
                            <div>
                                <img
                                    src={rcNubip}
                                    className="form-rc-nubip-logo-login"
                                    alt="College logo"
                                />
                            </div>
                            <div>
                                <h1 className="form-rc-nubip-title-login">
                                    ВСП "РФК НУБіП України"
                                </h1>
                            </div>
                        </div>
                        <h2 className="form-title-login">Репозитарій | Вхід</h2>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    message: "Не вірний формат поштової скриньки!",
                                },
                                {
                                    required: true,
                                    message: "Будь ласка введіть вашу поштову скриньку!",
                                },
                            ]}
                            className="form-item-login"
                            hasFeedback
                        >
                            <Input
                                prefix={<UserOutlined className="site-form-item-icon"/>}
                                className="form-input-login"
                                placeholder="Введіть поштову скриньку"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {required: true, message: "Будь ласка введіть ваш пароль!"},
                                {
                                    min: 8,
                                    message: "Пароль повинен мати щонайменше 8 символів!",
                                },
                            ]}
                            className="form-item-login"
                            hasFeedback
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="Введіть пароль"
                                className="form-input-login"
                                iconRender={(visible: any) =>
                                    visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                                }
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className="form-wrapper-remember-me">
                                <div>
                                    <Form.Item name="isRemember" valuePropName="checked" noStyle>
                                        <Checkbox>Запам'ятати мене</Checkbox>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Link to="/password-recovery">Відновити пароль</Link>
                                </div>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" className="form-btn-submit-login" htmlType="submit">
                                Вхід
                            </Button>
                            <GoogleAuth
                                handleGoogleOneTapSuccess={(response: any) => {
                                    const {credential} = response;

                                    GoogleLoginUser({
                                        provider: "Google",
                                        token: credential,
                                    } as IAuthProvider);
                                }}
                                handleGoogleSuccess={(response: any) => {
                                    GoogleOneTapLoginUser({
                                        provider: "Google",
                                        token: response.access_token,
                                    } as IAuthProvider);
                                }}
                                text="Увійти за допомогою Google"
                            />
                            <div className="form-under-title-login">
                                <label>Не маєте акаунту? </label>
                                <Link to="/register">
                                    Реєстрація
                                </Link>
                            </div>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </section>
    );
};

export default Login;
