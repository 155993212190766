export interface IUser {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    userName: string;
    avatarImage: string;
    roles: Array<string>;
}

export interface AuthState {
    user?: IUser;
    isAuth: boolean;
    privateDetails: IGetShortInfoResearchProject,
}

export enum AuthActionType {
    LOGIN_AUTH = "AUTH/LOGIN_AUTH",
    RELOGIN_AUTH = "AUTH/RELOGIN_AUTH",
    LOGIN_GOOGLE_AUTH = "AUTH/LOGIN_GOOGLE_AUTH",
    LOGOUT_AUTH = "AUTH/LOGOUT_AUTH",
    REGISTER_AUTH = "AUTH/REGISTER_AUTH",
    REGISTER_GOOGLE_AUTH = "AUTH/REGISTER_GOOGLE_AUTH",
    GET_SHORT_INFO_RESEARCH_PROJECT_AUTH = "AUTH/GET_SHORT_INFO_RESEARCH_PROJECT_AUTH",
    CONFIRM_EMAIL_AUTH = "AUTH/CONFIRM_EMAIL_AUTH",
    RECOVERY_PASSWORD = "AUTH/RECOVERY_PASSWORD",
    RECOVERY_PASSWORD_CONFIRM = "AUTH/RECOVERY_PASSWORD_CONFIRM",
}

export interface IGetShortInfoResearchProject {
    id: string;
    name: string;
    description: string;
}