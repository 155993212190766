import {Dispatch} from "react";
import {
    IGetRegisteredCategoriesSuccessAction,
    IGetRegisteredUsersSuccessAction,
    IGetUnderReviewRegisteredResearchProjectsSuccessAction,
    IGetVerifiedRegisteredResearchProjectsSuccessAction,
    ISetOpenSectionHeaderMenu,
    ISetUpdateCategoryModalSuccessAction,
} from "../../../types/home/headerTypes";
import axios from "axios";
import {message} from "antd";
import instance from "../../../api/configuration/configure";
import {HomeActionType} from "../../../types/stores/homeStoreTypes";

export const GetRegisteredUsers = () => {
    return async (dispatch: Dispatch<IGetRegisteredUsersSuccessAction>) => {
        try {
            const response = await instance.get("api/statistic/users");
            dispatch({
                type: HomeActionType.REGISTERED_USERS_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const GetRegisteredCategories = () => {
    return async (dispatch: Dispatch<IGetRegisteredCategoriesSuccessAction>) => {
        try {
            const response = await instance.get("api/statistic/categories");
            dispatch({
                type: HomeActionType.REGISTERED_CATEGORIES_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const SetOpenSectionHeaderMenu = (value: boolean) => {
    return async (dispatch: Dispatch<ISetOpenSectionHeaderMenu>) => {
        dispatch({
            type: HomeActionType.SET_OPEN_SECTION_HEADER_MENU_OPEN_HOME,
            payload: value,
        });

        return Promise.resolve();
    };
};

export const GetVerifiedRegisteredResearchProjects = () => {
    return async (
        dispatch: Dispatch<IGetVerifiedRegisteredResearchProjectsSuccessAction>
    ) => {
        try {
            const response = await instance.get("api/statistic/verified-research-projects");
            dispatch({
                type: HomeActionType.REGISTERED_VERIFIED_RESEARCH_PROJECTS_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const GetUnderReviewRegisteredResearchProjects = () => {
    return async (
        dispatch: Dispatch<IGetUnderReviewRegisteredResearchProjectsSuccessAction>
    ) => {
        try {
            const response = await instance.get("api/statistic/under-review-research-projects");
            dispatch({
                type: HomeActionType.REGISTERED_UNDER_REVIEW_RESEARCH_PROJECTS_HOME,
                payload: response.data,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const SetOpenUpdateCategoryModal = (value: boolean) => {
    return async (dispatch: Dispatch<ISetUpdateCategoryModalSuccessAction>) => {
        try {
            dispatch({
                type: HomeActionType.SET_OPEN_UPDATE_CATEGORY_HOME,
                payload: value,
            });

            return Promise.resolve();
        } catch (err: any) {
            return Promise.reject();
        }
    };
};

export const SetAvatarImage = (file: any) => {
    return async () => {
        try {
            const formData = new FormData();

            formData.append("image", file);

            const response = await instance.put(
                "api/user/set-avatar-image",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        withCredentials: true,
                    },
                }
            );

            message.success(response?.data.message);
            return response?.data.image;
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                message.error(err.response?.data.message);
            }

            return null;
        }
    };
};
