import {GoogleOAuthProvider} from "@react-oauth/google";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {Outlet} from "react-router-dom";

const AuthLayout = () => {
    return (
        <div className="dark-background">
            <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID as string}
            >
                <GoogleReCaptchaProvider
                    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                >
                    <Outlet/>
                </GoogleReCaptchaProvider>
            </GoogleOAuthProvider>
        </div>
    );
};

export default AuthLayout;
