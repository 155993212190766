import '../../css/components/home/menus/header.css';

import {BarsOutlined, FileProtectOutlined, UserOutlined,} from "@ant-design/icons";
import {Statistic, Tooltip} from "antd";
import {lazy, Suspense, useEffect, useState} from "react";
import Paragraph from "antd/es/typography/Paragraph";
import {useMediaQuery} from "react-responsive";
import {IUser} from "../../types/stores/authStoreTypes";
import {useTypedSelector} from "../../hooks/redux/useTypedSelector";
import {useActions} from "../../hooks/redux/useActions";
import {getAvatarImage} from "../common/avatarImage/getAvatarImage";
import SearchMenu from "./searchMenu";

const SectionHeaderMenu = lazy(() => import("./sectionHeaderMenu"));

const MainHeader = () => {
    const desktopXtablet = useMediaQuery({minWidth: 768});
    const smartphone = useMediaQuery({maxWidth: 767});

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [avatarImageUrl, setAvatarImageUrl] = useState<string>();
    const [userImage, setUserImage] = useState<IUser>();

    const {user} = useTypedSelector((x) => x.auth);
    const {
        registeredCategories,
        registeredUnderReviewProjects,
        registeredVerifiedProjects,
        registeredUsers,
    } = useTypedSelector((x) => x.home);

    const {
        GetRegisteredCategories,
        GetVerifiedRegisteredResearchProjects,
        GetUnderReviewRegisteredResearchProjects,
        GetRegisteredUsers,
        SetOpenSectionHeaderMenu,
    } = useActions();

    useEffect(() => {
        GetRegisteredCategories();
        GetVerifiedRegisteredResearchProjects();
        GetUnderReviewRegisteredResearchProjects();
        GetRegisteredUsers();
    }, []);

    useEffect(() => {
        if (!isLoaded) {
            setUserImage(user);
            setIsLoaded(true);
        }
    }, [user]);

    useEffect(() => {
        fetchAvatarImage();

        return (() => {
            if (typeof avatarImageUrl == 'string' && avatarImageUrl.includes("blob")) {
                URL.revokeObjectURL(avatarImageUrl);
            }
        });
    }, [userImage]);

    const fetchAvatarImage = async () => {
        setAvatarImageUrl(await getAvatarImage(userImage?.avatarImage as string));
    };

    const getHighestRole = (roles: Array<String>) => {
        if (roles.includes("Адміністратор")) {
            return "Адміністратор";
        } else if (roles.includes("Викладач")) {
            return "Викладач";
        } else if (roles.includes("Студент")) {
            return "Студент";
        } else if (roles.includes("Гість")) {
            return "Гість";
        }
    };

    return (
        <>
            {desktopXtablet && (<div className="header-wrapper">
                <div className="header-first-part">
                    <SearchMenu/>
                </div>
                <section className="header-second-part">
                    <div className="header-tooltip-statistic-wrapper noselect">
                        <Tooltip
                            placement="topLeft"
                            title="Кількість зареєстрованих користувачів"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={registeredUsers}
                                valueStyle={{color: "#ACD1AF"}}
                                prefix={<UserOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="topLeft"
                            title="Кількість затверджених наукових робіт"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={registeredVerifiedProjects}
                                valueStyle={{color: "#1677ff"}}
                                prefix={<FileProtectOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="topLeft"
                            title="Кількість наукових робіт на затвердженні"
                        >
                            <Statistic
                                className="header-tooltip-statistic"
                                value={registeredUnderReviewProjects}
                                valueStyle={{color: "#FAAD14"}}
                                prefix={<FileProtectOutlined/>}
                            />
                        </Tooltip>
                        <Tooltip placement="topLeft" title="Кількість спеціальностей">
                            <Statistic
                                className="header-tooltip-statistic"
                                value={registeredCategories}
                                valueStyle={{color: "#FF7A45"}}
                                prefix={<BarsOutlined/>}
                            />
                        </Tooltip>
                    </div>
                    <div
                        className="header-user-info-wrapper noselect"
                        onClick={() => {
                            SetOpenSectionHeaderMenu(true);
                        }}
                    >
                        <div className="header-user-info-text">
                            <Paragraph className="header-user-info-title-name">
                                {user?.lastName + " " + user?.firstName + " " + user?.middleName}
                            </Paragraph>
                            <Paragraph className="header-user-info-title-role">
                                {getHighestRole(user?.roles as String[])}
                            </Paragraph>
                        </div>
                        <div>
                            <img
                                width={50}
                                height={50}
                                src={avatarImageUrl}
                                className="avatar-image"
                                alt="Avatar image"
                            />
                        </div>
                    </div>
                </section>
            </div>)}

            {smartphone && (<div className="header-wrapper">
                <div>
                    <SearchMenu/>
                </div>
                <div
                    className="header-user-info-wrapper noselect"
                    onClick={() => {
                        SetOpenSectionHeaderMenu(true);
                    }}
                >
                    <div className="header-user-info-text">
                        <Paragraph className="header-user-info-title-name">
                            {user?.lastName + " " + user?.firstName + " " + user?.middleName}
                        </Paragraph>
                        <Paragraph className="header-user-info-title-role">
                            {getHighestRole(user?.roles as String[])}
                        </Paragraph>
                    </div>
                    <div>
                        <img
                            width={50}
                            height={50}
                            src={avatarImageUrl}
                            className="avatar-image"
                            alt="Avatar image"
                        />
                    </div>
                </div>
            </div>)}

            <Suspense fallback={<></>}>
                <SectionHeaderMenu avatarImageUrl={avatarImageUrl}/>
            </Suspense>
        </>
    );
};

export default MainHeader;
