import instance from "../../../api/configuration/configure";

const fetchAvatarImage = async (image: string) => {
    const response = await instance.get(
        `${process.env.REACT_APP_SERVER_URL}storage/image?filename=${image}`,
        {
            responseType: "blob",
        }
    );
    return URL.createObjectURL(response.data);
};

export const getAvatarImage = async (avatar: string) => {
    const defaultAvatar = process.env.REACT_APP_DEFAULT_USER_REACT_IMAGE as string;

    if (!avatar) {
        return defaultAvatar;
    }

    const storage = localStorage.getItem("avatarImage") as string;

    if (storage) {
        return await fetchAvatarImage(storage) || defaultAvatar;
    }

    if (avatar.includes("https://")) {
        return avatar;
    }

    if (avatar) {
        return await fetchAvatarImage(avatar) || defaultAvatar;
    }

    return defaultAvatar;
};
