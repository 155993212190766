import '../../../css/components/auth/passwordRecoveryConfirmation.css';

import {EyeInvisibleOutlined, EyeTwoTone, LockOutlined,} from "@ant-design/icons";
import {Button, Form, Input} from "antd";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from 'react';
import {IRecoveryConfirmPassoword} from "../../../types/auth/passwordRecoveryTypes";
import {useActions} from "../../../hooks/redux/useActions";

const PasswordRecoveryConfirmation = () => {
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate();
    const {RecoveryPasswordConfirm} = useActions();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        document.title = "Відновлення паролю | РФК НУБіП";
    }, []);

    const onFinish = async (values: any) => {
        try {
            if (!executeRecaptcha) {
                return;
            }

            const reCaptchaToken = await executeRecaptcha();

            RecoveryPasswordConfirm({
                email: searchParams.get("email"),
                newPassword: values.password,
                token: searchParams.get("token"),
                reCaptchaToken: reCaptchaToken,
            } as IRecoveryConfirmPassoword);
        } catch (error) {
            setTimeout(() => {
                navigate("/password-recovery-confirm-result");
            }, 2000);
        }
    };

    return (
        <section className="section-password-recovery-confirmation hide-scrollbar">
            <Form
                initialValues={{remember: true}}
                autoComplete="on"
                onFinish={onFinish}
                scrollToFirstError
            >
                <div className="form-center-div-password-recovery-confirmation">
                    <h1 style={{textAlign: "center"}}>Відновлення паролю</h1>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Будь ласка введіть ваш новий пароль!",
                            },
                            {
                                min: 8,
                                message: "Пароль повинен мати щонайменше 8 символів!",
                            },
                        ]}
                        className="form-item-recovery-password-confirmation"
                        hasFeedback
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            className="form-input-recovery-password-confirmation"
                            placeholder="Введіть новий пароль"
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        dependencies={["password"]}
                        className="form-item-recovery-password-confirmation"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: "Будь ласка введіть ваш новий пароль!",
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("Ваші паролі не співпадають!")
                                    );
                                },
                            }),
                            {
                                min: 8,
                                message: "Пароль повинен мати щонайменше 8 символів!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon"/>}
                            type="password"
                            className="form-input-recovery-password-confirmation"
                            placeholder="Введіть новий пароль повторно"
                            iconRender={(visible) =>
                                visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                            }
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" className="form-btn-submit-recover-password-confirmation"
                                htmlType="submit">
                            Надіслати
                        </Button>
                        <div className="form-under-title-recover-password-confirmation">
                            <label>Хочете повернутися? </label>
                            <Link to="/login">
                                Вхід
                            </Link>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </section>
    );
};

export default PasswordRecoveryConfirmation;
