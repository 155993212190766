import {
    HomeActionType,
    HomeState,
    IApprovementProject,
    IApprovementStudent,
    ICategory,
    ICategoryResearchProject,
    IGetApprovementProject,
    IGetApprovementStudent,
    IGetResearchProjectDetails,
    IGetStudentFromExcel,
    IGetUpdateProjectLoadData,
    IGetUserManageList,
    IResearchProject,
    ISearchParams,
    IUpdateCategoryData,
    IUserManageList
} from "../../types/stores/homeStoreTypes";

const initialState: HomeState = {
    categories: [] as Array<ICategory>,
    yearsList: [] as Array<string>,
    authorsList: [] as Array<string>,
    usersManageList: {
        items: [] as Array<IUserManageList>,
        totalCount: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
    } as IGetUserManageList,
    siderData: {
        items: [] as Array<IResearchProject>,
        totalCount: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
    } as ICategoryResearchProject,
    excelAddResponse: {
        goodRows: 0,
        badRows: 0,
    } as IGetStudentFromExcel,
    isOpenSectionSiderFiltersMenu: false,
    isOpenSectionHeaderMenu: false,
    isOpenCreateProjectModal: false,
    isOpenUsersManageListModal: false,
    isOpenEditCategoryModal: false,
    isOpenStudentPromoteModal: false,
    isOpenRoleModal: false,
    isOpenAddStudentModal: false,
    isOpenUpdateCategoryModal: false,
    isOpenStudentListModal: false,
    isOpenProjectApprovementModal: false,
    isOpenUpdateResearchProjectModal: false,
    createResearchProjectData: "",
    updateProjectData: {
        id: "",
        name: "",
        description: "",
        categoryName: "",
        type: 0,
        tags: [],
    } as IGetUpdateProjectLoadData,
    updateCategoryData: {
        id: "",
        name: "",
    } as IUpdateCategoryData,
    details: {
        id: "",
        name: "",
        fileName: "",
        coverImageName: "",
        description: "",
        categoryName: "",
        author: "",
        authorAvatar: "",
        dateCreated: "",
        dateEdited: "",
        type: 0,
        tags: [],
        isVerified: null,
    } as IGetResearchProjectDetails,
    searchParams: {
        query: "",
        pageIndex: 1,
        year: "",
        categoryId: "",
        fullName: "",
        type: null,
        tags: [] as Array<string>,
        rejectedProjects: "",
        ownProjects: false,
    } as ISearchParams,
    registeredUsers: 0,
    registeredCategories: 0,
    registeredVerifiedProjects: 0,
    registeredUnderReviewProjects: 0,
    emailUserList: [] as Array<string>,
    roleList: [] as Array<string>,
    studentList: {
        items: [] as Array<IApprovementStudent>,
        totalCount: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
    } as IGetApprovementStudent,
    projectApprovementList: {
        items: [] as Array<IApprovementProject>,
        totalCount: 0,
        pageIndex: 0,
        pageSize: 0,
        totalPages: 0,
        hasPreviousPage: false,
        hasNextPage: false,
    } as IGetApprovementProject,
};

export const homeReducer = (state = initialState, action: any): HomeState => {
    switch (action.type) {
        case HomeActionType.GET_USERS_MANAGE_LIST_HOME:
            return {
                ...state,
                usersManageList: action.payload,
            };
        case HomeActionType.SET_SIDER_QUERY_HOME:
            return {
                ...state,
                searchParams: {
                    ...state.searchParams,
                    query: action.payload,
                }
            };
        case HomeActionType.DELETE_FROM_LIST_BY_ID_HOME:
            return {
                ...state,
                siderData: {
                    ...state.siderData,
                    items: state.siderData.items.filter((x) => x.id !== action.payload),
                },
            };
        case HomeActionType.GET_CATEGORIES_HOME:
            return {
                ...state,
                categories: [...action.payload],
            };
        case HomeActionType.GET_PROJECT_APPROVEMENT_LIST_HOME:
            return {
                ...state,
                projectApprovementList: action.payload,
            };
        case HomeActionType.GET_STUDENT_LIST_HOME:
            return {
                ...state,
                studentList: action.payload,
            };
        case HomeActionType.SET_FILTERS_HOME:
            return {
                ...state,
                searchParams: action.payload,
            };
        case HomeActionType.GET_YEARS_LIST_HOME:
            return {
                ...state,
                yearsList: [...action.payload],
            };
        case HomeActionType.GET_AUTHORS_LIST_HOME:
            return {
                ...state,
                authorsList: [...action.payload],
            };
        case HomeActionType.GET_RESEARCH_PROJECT_DATA_HOME:
            return {
                ...state,
                updateProjectData: action.payload,
            };
        case HomeActionType.GET_SET_RESEARCH_PROJECTS_HOME:
            return {
                ...state,
                siderData: action.payload,
            };
        case HomeActionType.UPDATE_RESEARCH_PROJECT_HOME:
            return {
                ...state,
                details: {
                    id: "",
                    name: "",
                    fileName: "",
                    coverImageName: "",
                    description: "",
                    categoryName: "",
                    author: "",
                    authorAvatar: "",
                    dateCreated: "",
                    dateEdited: "",
                    type: 0,
                    tags: [],
                    isVerified: null,
                } as IGetResearchProjectDetails,
                siderData: {
                    ...state.siderData,
                    items: state.siderData.items.filter(
                        (item) => item.id !== action.payload
                    ),
                },
                updateProjectData: {
                    id: "",
                    name: "",
                    description: "",
                    categoryName: "",
                    type: 0,
                    tags: [] as Array<string>,
                } as IGetUpdateProjectLoadData,
            };
        case HomeActionType.GET_RESEARCH_PROJECTS_HOME:
            let temp = action.payload;
            temp.items = [...state.siderData.items, ...temp.items];

            return {
                ...state,
                siderData: temp,
            };
        case HomeActionType.GET_CLEAR_RESEARCH_PROJECTS_HOME:
            return {
                ...state,
                siderData: action.payload,
            };
        case HomeActionType.SET_OPEN_SECTION_SIDER_FILTERS_MENU:
            return {
                ...state,
                isOpenSectionSiderFiltersMenu: action.payload,
            };
        case HomeActionType.SET_OPEN_SECTION_HEADER_MENU_OPEN_HOME:
            return {
                ...state,
                isOpenSectionHeaderMenu: action.payload,
            };
        case HomeActionType.SET_OPEN_CREATE_PROJECT_MODAL_HOME:
            return {
                ...state,
                isOpenCreateProjectModal: action.payload,
            };
        case HomeActionType.SET_OPEN_UPDATE_RESEARCH_PROJECT_MODEL_HOME:
            return {
                ...state,
                isOpenUpdateResearchProjectModal: action.payload,
            };
        case HomeActionType.SET_OPEN_USERS_MANAGE_LIST_MENU_H0ME:
            return {
                ...state,
                isOpenUsersManageListModal: action.payload,
            };
        case HomeActionType.SET_OPEN_EDIT_CATEGORY_HOME:
            return {
                ...state,
                isOpenEditCategoryModal: action.payload,
            };
        case HomeActionType.SET_OPEN_STUDENT_PROMOTE_HOME:
            return {
                ...state,
                isOpenStudentPromoteModal: action.payload,
            };
        case HomeActionType.SET_OPEN_ROLE_HOME:
            return {
                ...state,
                isOpenRoleModal: action.payload,
            };
        case HomeActionType.SET_OPEN_ADD_STUDENT_HOME:
            return {
                ...state,
                isOpenAddStudentModal: action.payload,
            };
        case HomeActionType.SET_OPEN_UPDATE_CATEGORY_HOME:
            return {
                ...state,
                isOpenUpdateCategoryModal: action.payload,
            };
        case HomeActionType.SET_OPEN_STUDENT_LIST_MODEL_HOME:
            return {
                ...state,
                isOpenStudentListModal: action.payload,
            };
        case HomeActionType.SET_OPEN_PROJECT_APPROVEMENT_MODEL_HOME:
            return {
                ...state,
                isOpenProjectApprovementModal: action.payload,
            };
        case HomeActionType.CREATE_CATEGORY_HOME:
            return {
                ...state,
            };
        case HomeActionType.CREATE_RESEARCH_PROJECT_HOME:
            return {
                ...state,
            };
        case HomeActionType.SET_FILE_CREATE_PROJECT_MODEL:
            return {
                ...state,
                createResearchProjectData: action.payload,
            };
        case HomeActionType.GET_RESEARCH_PROJECT_DETAILS_HOME:
            return {
                ...state,
                details: {...action.payload},
            };
        case HomeActionType.DELETE_CATEGORY_HOME:
            return {
                ...state,
                categories: state.categories.filter(
                    (item) => item.id !== action.payload
                ),
            };
        case HomeActionType.UPDATE_CATEGORY_HOME:
            const updatedCategories = state.categories.map((category) => {
                if (category.id === action.payload.id) {
                    return {
                        ...category,
                        name: action.payload.name,
                    };
                }
                return category;
            });

            return {
                ...state,
                categories: updatedCategories,
            };
        case HomeActionType.UPDATE_CATEGORY_DATA_HOME:
            return {
                ...state,
                updateCategoryData: action.payload,
            };
        case HomeActionType.REGISTERED_USERS_HOME:
            return {
                ...state,
                registeredUsers: action.payload,
            };
        case HomeActionType.REGISTERED_CATEGORIES_HOME:
            return {
                ...state,
                registeredCategories: action.payload,
            };
        case HomeActionType.REGISTERED_VERIFIED_RESEARCH_PROJECTS_HOME:
            return {
                ...state,
                registeredVerifiedProjects: action.payload,
            };
        case HomeActionType.REGISTERED_UNDER_REVIEW_RESEARCH_PROJECTS_HOME:
            return {
                ...state,
                registeredUnderReviewProjects: action.payload,
            };
        case HomeActionType.GET_EMAIL_USER_LIST_HOME:
            return {
                ...state,
                emailUserList: [...action.payload],
            };
        case HomeActionType.GET_ROLE_LIST_HOME:
            return {
                ...state,
                roleList: [...action.payload],
            };
        case HomeActionType.SET_EMPTY_MEDIA_FIELDS:
            return {
                ...state,
                createResearchProjectData: "",
            };
        case HomeActionType.CHANGE_AVATAR_IMAGE:
            localStorage.setItem("avatarImage", action.payload);

            return {
                ...state,
            };
        case HomeActionType.CREATE_STUDENTS_EXCEL_HOME:
            return {
                ...state,
                excelAddResponse: action.payload,
            };
        case HomeActionType.SET_DETAILS_PROJECT_HOME:
            return {
                ...state,
                details: {
                    id: "",
                    name: "",
                    fileName: "",
                    coverImageName: "",
                    description: "",
                    categoryName: "",
                    author: "",
                    authorAvatar: "",
                    dateCreated: "",
                    dateEdited: "",
                    type: 0,
                    tags: [],
                    isVerified: null,
                } as IGetResearchProjectDetails,
            };
    }
    return state;
};
